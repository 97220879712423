import React, {useState} from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import SEO from "../components/seo"

const Wordpress = () => {


  return(
  <Layout className='bg-slate-50'>
    <SEO title={'Brandon Jewell'} description={"I've spent the last decade designing, developing, and problem solving for startups, unicorns, and other businesses."}/>
    <div className='max-w-[550px] mx-auto px-4 py-16 article'>
    	<h1>🚀 The Odyssey: A Solo Marketing Developer's Tale</h1>
    	<p>Welcome to the epic saga of myself, a maverick in the realms of web development and marketing tech, whose decade-long adventure has been nothing short of a digital odyssey.</p>


    	<h1>🌟 The Rise of a Unicorn: The Uplight Chronicles</h1>
    	<p>In the bustling tech haven of Boulder, CO, I embarked on a thrilling journey with Uplight, a then-nascent startup with unicorn aspirations. As the solo marketing developer, I was entrusted with the herculean task of creating a digital ecosystem robust enough to power the company’s ambitious endeavors.</p>



    	<h1>🛠 Master of Many Realms</h1>
    	<p>My role? A multifaceted tapestry of responsibilities, weaving together web development, email marketing, and an array of tech marvels. From the expansive B2B corporate website to the intricacies of email templates and customer success portals, my digital loom was always in full swing.</p>



    	<h1>📈 Triumphs and Trophies</h1>
    	<p>The result? A high-traffic B2B website boasting 6-figure monthly visits, a content marketing system rivalling the halls of Valhalla, and a plethora of marketing integrations that sang in perfect harmony. Uplight’s story was now being told far and wide, and I was right there, scripting its digital destiny.</p>



    	<h1>🔧 The Tech Alchemist’s Toolbox</h1>
    	<p>With WordPress as my trusty steed, and a arsenal of tools from SASS to TailwindCSS, React to Next.js, my tech toolbox was as vast as it was versatile. Vercel and WPEngine stood as the guardians of our digital realm, ensuring that every byte and pixel was in its prime form.</p>


    	<h1>💬 The Testimonies of Triumph</h1>
    	<p>Don’t just take my word for it - the creatives and marketing maestros of Uplight have sung praises, ready to be shared with those curious enough to inquire.</p>


    	<h1>🛡 The Next Chapter Awaits</h1>
    	<p>Now, as I stand at the crossroads of the next grand chapter, my arsenal is polished, and my skills, sharper than ever. Ready to bring my decade of experience and saga of success to new frontiers.</p>


    	<p>Ready for the Odyssey experience? Let the next adventure begin. connect@brandonjewell.com</p>

    </div>

  </Layout>
)}

export default Wordpress